import { Link, useLocation } from "@remix-run/react";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import * as config from "~/config";
import { subscribeEmailBeehiveLink } from "~/config";
import { useCurrentUser } from "~/hooks/use-current-user";
import { useIsCollapse } from "~/hooks/use-is-collapse";
import type { Collector, Game, Tag } from "~/types";
import { cn } from "~/util/css";
import StackedUserAvatars from "./stacked-user-avatars";

const tabs = [
  {
    label: "Predictions",
    to: "/predictions",
    icon: "",
    match: /^\/predictions/,
    isVisible: config.features.enableMenuLinksRemix,
  },
  {
    label: "Remix Home",
    to: "/remix",
    icon: "",
    match: /^\/remix/,
    isVisible: config.features.enableMenuLinksRemix,
  },
  {
    label: "Lobby",
    to: "/",
    icon: "/icons/lobby-y2kCamera.svg",
    match: /^\/lobby/,
    isVisible: true,
  },
  {
    label: "Quests",
    to: "/quests",
    icon: "/icons/quests.svg",
    match: /^\/quests/,
    isVisible: true,
  },
  {
    label: "Bounties",
    to: "/bounties",
    match: /^\/bounties/,
    isVisible: true,
    icon: "/icons/bounty.svg",
  },
  {
    label: "Games",
    to: "/games",
    icon: "/icons/joystick.svg",
    match: /^\/games/,
    isVisible: true,
  },
  {
    label: "Communities",
    to: "/communities",
    icon: "/icons/community.svg",
    match: /^\/communities/,
    isVisible: config.features.enableGuilds,
  },
  {
    label: "Leaderboard",
    to: "/leaderboard",
    icon: "/icons/leaderboard.svg",
    match: /^\/leaderboard/,
    isVisible: true,
  },
  {
    label: "Sticker Shop",
    to: "/sticker-shop",
    icon: "/icons/shop.svg",
    match: /^\/sticker-shop/,
    isVisible: true,
  },
  {
    label: "Streams",
    to: "/streams",
    icon: "/icons/streams.svg",
    match: /^\/streams/,
    isVisible: true,
  },
  {
    label: "Soulmates",
    to: "/soulmates",
    icon: "/icons/soulmates.svg",
    match: /^\/soulmates/,
    isVisible: true,
  },
];

interface SidenavProps {
  className?: string;
}

export function Sidenav(props: SidenavProps) {
  const { pathname } = useLocation();
  const [isCollapse] = useIsCollapse();
  const [currentUser] = useCurrentUser();

  const menuTabs = tabs.map((tab) => ({
    ...tab,
    isVisible: tab.to === "/soulmates" ? Boolean(currentUser) : tab.isVisible,
  }));

  return (
    <div
      className={cn(
        "w-full flex flex-col items-center h-full relative",
        props.className,
      )}
    >
      <Scrollbars autoHide universal>
        <div className="flex flex-col items-center h-full py-5 px-6">
          <div className="flex-1 w-full flex flex-col items-center">
            <Link to="/" className="h-[96px] mb-4">
              {isCollapse ? (
                <img
                  src="/soulbound-beta-icon.webp"
                  alt="Soulbound"
                  className="w-[66px] h-[84px]"
                />
              ) : (
                <img
                  src="/soulbound-beta.webp"
                  alt="Soulbound"
                  className="w-[185px]"
                />
              )}
            </Link>
            <div className="flex flex-col gap-4 w-full">
              <div
                className={cn("w-full flex flex-col gap-3 mb-5", {
                  "mb-0 items-center": isCollapse,
                })}
              >
                {menuTabs
                  .filter((t) => t.isVisible)
                  .map((tab) => (
                    <Link
                      key={tab.label}
                      className={cn(
                        "px-4 py-2 flex items-center rounded-full w-full font-medium hover:bg-gray-100 hover:bg-[radial-gradient(116.35%_116.25%_at_53.26%_0%,#FFF_0%,#CBE3FF_18.75%,#FFA8DC_50%,#FFF_96.35%)]",
                        {
                          "bg-[radial-gradient(116.35%_116.25%_at_53.26%_0%,#FFF_6%,#FFA8DC_36%,#CBE3FF_71.5%,#FFF_100%)]":
                            tab.match.test(
                              pathname === "/" ? "/lobby" : pathname,
                            ),
                          "justify-center w-[64px]": isCollapse,
                        },
                      )}
                      to={tab.to}
                    >
                      {isCollapse ? (
                        <img className="h-8 w-8" src={tab.icon} alt="icon" />
                      ) : (
                        <span>{tab.label}</span>
                      )}
                    </Link>
                  ))}
              </div>
              <div
                className={cn("flex flex-col gap-5 w-full", {
                  "border-t border-[#C2BEFF] pt-5": isCollapse,
                })}
              >
                <TopUsers />
                <TopGames />
                <Tags />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-3 text-white pt-6 w-full">
            <Links />
            <Copyright />
          </div>
        </div>
      </Scrollbars>
    </div>
  );
}

interface LinkSidenav {
  title: string;
  to: string;
}

export function SocialLinks() {
  const socials = [
    {
      title: "Telegram",
      icon: "/icons/telegram-light.svg",
      link: "https://t.me/Soulbound_GG",
      size: "21px",
    },
    {
      title: "Discord",
      icon: "/icons/discord-white.svg",
      link: "https://discord.com/invite/soulboundinc",
      size: "16px",
    },
    {
      title: "Twitter",
      icon: "/icons/x-light.svg",
      link: "https://twitter.com/soulbound_gg",
      size: "18px",
    },
    {
      title: "Litepaper",
      icon: "/icons/gitbook.svg",
      link: "https://soulbound.gitbook.io/",
      size: "16px",
    },
    {
      title: "Subscribe To Our Newsletter",
      icon: "/icons/mail-light.svg",
      link: subscribeEmailBeehiveLink[0],
      size: "16px",
    },
    {
      title: "Medium",
      icon: "/icons/medium-light.svg",
      link: "https://medium.com/@soulbound.gg",
      size: "18px",
    },
    {
      title: "Blog",
      icon: "/icons/blog-icon.svg",
      link: "/blog",
      size: "20px",
    },
  ];

  return (
    <div className={cn("flex items-center gap-2 flex-wrap")}>
      {socials.map((social, index) => (
        <a href={social.link} target="_blank" rel="noreferrer" key={index}>
          <img
            src={social.icon}
            alt={social.title}
            style={{ width: social.size, height: social.size }}
            className="hover:scale-110"
          />
        </a>
      ))}
    </div>
  );
}

export function Links() {
  const links: LinkSidenav[] = [
    // TODO: unhide it once contact us page design is ready
    // {
    //   title: "Contact us",
    //   to: "/contact-us",
    // },
    {
      title: "Privacy Policy",
      to: "/privacy-policy",
    },
    {
      title: "Terms of service",
      to: "/terms-of-service",
    },
  ];

  return (
    <div className="flex flex-col gap-4">
      <p className="text-sm font-medium whitespace-nowrap">Follow us</p>
      <SocialLinks />
      {links.length > 0 && (
        <div className="flex items-center flex-wrap gap-1">
          {links.map((link, index) => (
            <div
              key={index}
              className="flex items-center gap-1 uppercase text-[10px]"
            >
              <Link to={link.to} className="hover:underline">
                {link.title}
                {links.length - 1 > index && <span className="ml-1">⋆</span>}
              </Link>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

function Copyright() {
  return (
    <div className="flex items-center gap-1">
      <p className="text-[10px] uppercase  text-[rgba(232,222,255,0.56)]">
        © 2024 Soulbound Inc.
      </p>
    </div>
  );
}

function TopUsers() {
  const [isCollapse] = useIsCollapse();

  const getTopUsers = async (): Promise<Collector[]> => {
    try {
      const res = await fetch(`${window.ENV.EDGE_URL}/users/top`);
      const data = await res.json();
      return data;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const { data, isLoading: loading } = useQuery({
    queryKey: ["top-users"],
    queryFn: () => getTopUsers(),
  });
  const topUsers = data || [];

  return (
    <div
      className={cn({
        "border-b border-[#C2BEFF] pb-5": isCollapse,
      })}
    >
      <div
        className={cn(
          "border border-[#C69DFF] rounded-lg p-2 flex flex-col gap-4 w-full bg-[linear-gradient(122deg,rgba(198,169,236,0.37)_16.13%,rgba(107,104,255,0.30)_84.03%)]",
          {
            "bg-[linear-gradient(122deg,rgba(105,76,189,0.70)_16.13%,rgba(107,104,255,0.21)_98.86%)]":
              isCollapse,
          },
        )}
      >
        <p
          className={cn("font-medium text-sm", {
            "text-center text-white": isCollapse,
          })}
        >
          Top Users
        </p>
        <div
          className={cn("flex items-center justify-center relative w-full", {
            "flex-col bg": isCollapse,
          })}
        >
          {loading ? (
            <div className="flex items-center justify-center relative w-full">
              {Array.from({ length: 5 }).map((_, index) => (
                <div
                  key={index}
                  className={`ml-${-10 * index}`}
                  style={{ zIndex: index }}
                >
                  <div className="w-[38px] h-[38px] rounded-full overflow-hidden bg-gray-300 animate-pulse" />
                </div>
              ))}
            </div>
          ) : (
            topUsers.map((user, index) => (
              <StackedUserAvatars
                index={index}
                key={user.username}
                isHorizontal={isCollapse}
                avatar_url={user.avatar_url}
                username={user.username}
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
}

function Tags() {
  const [tags, setTags] = React.useState<Tag[]>([]);

  React.useEffect(() => {
    fetch("/api/tags")
      .then((res) => res.json())
      .then((data) => setTags(data.tags))
      .catch(console.error);
  }, []);

  return (
    <>
      {tags.length > 0 && (
        <div className="flex flex-col gap-4">
          <span className="font-medium text-sm">Tags</span>
          <div className="flex flex-wrap items-start content-start self-stretch gap-[6px]">
            {tags.map((tag) => (
              <Link
                to={`/hashtag/${tag.name}`}
                className="flex justify-center items-center px-1.5 py-1 rounded-full bg-[#99E6FF] text-sm font-medium"
                key={`${tag.id}-${tag.name}`}
              >
                #{tag.name}
              </Link>
            ))}
          </div>
        </div>
      )}
    </>
  );
}

function TopGames() {
  const [isCollapse] = useIsCollapse();

  const getTopGames = async (): Promise<Game[]> => {
    try {
      const res = await fetch(`${window.ENV.EDGE_URL}/games/top`);
      const data = await res.json();
      return data;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const { data, isLoading: loading } = useQuery({
    queryKey: ["top-games"],
    queryFn: () => getTopGames(),
  });
  const topGames = data || [];

  return (
    <div
      className={cn({
        "border-b border-[#C2BEFF] pb-5": isCollapse,
      })}
    >
      <div
        className={cn(
          "border border-[#C69DFF] rounded-lg p-2 flex flex-col gap-4 w-full bg-[linear-gradient(122deg,rgba(198,169,236,0.37)_16.13%,rgba(107,104,255,0.30)_84.03%)]",
          {
            "bg-[linear-gradient(122deg,rgba(105,76,189,0.70)_16.13%,rgba(107,104,255,0.21)_98.86%)]":
              isCollapse,
          },
        )}
      >
        <p
          className={cn("font-medium text-sm", {
            "text-center text-white": isCollapse,
          })}
        >
          Trending Games
        </p>
        {loading ? (
          <SkeletonItem />
        ) : (
          <div
            className={cn(
              "grid grid-cols-3 items-center justify-center relative w-full gap-2",
              {
                "grid-cols-1": isCollapse,
              },
            )}
          >
            {topGames.map((game) => (
              <Link to={`/games/${game.slug}`} key={game.slug}>
                <img
                  src={game.image_url ?? "/default-avatar.svg"}
                  alt={game.slug}
                  className={cn(
                    "w-full h-full aspect-square rounded-lg overflow-hidden object-cover relative border border-[#FFA9DE] transition-transform duration-300 ease-in-out hover:scale-110",
                  )}
                />
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export function SkeletonItem() {
  return (
    <div className="grid grid-cols-3 items-center justify-center relative w-full gap-2">
      <div className="w-full h-full aspect-square rounded-lg overflow-hidden bg-gray-300 animate-pulse" />
      <div className="w-full h-full aspect-square rounded-lg overflow-hidden bg-gray-300 animate-pulse" />
      <div className="w-full h-full aspect-square rounded-lg overflow-hidden bg-gray-300 animate-pulse" />
    </div>
  );
}
